<script>
export default {
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 3
    },
    totalPages: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    },
  },
  computed: {
    startPage() {
      if (this.currentPage === 1) {
        return 1;
      }

      if (this.currentPage === this.totalPages) {
        return this.totalPages - this.maxVisibleButtons + 1;
      }

      return this.currentPage - 1;

    },
    endPage() {

      return Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);

    },
    pages() {
      const range = [];

      for (let i = this.startPage; i <= this.endPage; i+= 1 ) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage
        });
      }

      return range;
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.totalPages;
    },
  },
  methods: {
    onClickFirstPage() {
      this.$emit('page-changed', 1);
    },
    onClickPreviousPage() {
      this.$emit('page-changed', this.currentPage - 1);
    },
    onClickPage(page) {
      console.log(page)
      this.$emit('page-changed', page);
    },
    onClickNextPage() {
      this.$emit('page-changed', this.currentPage + 1);
    },
    onClickLastPage() {
      this.$emit('page-changed', this.totalPages);
    },
    isPageActive(page) {
      return this.currentPage === page;
    },
  }
};
</script>

<template>
  <div class="pagination-wrap hstack gap-2" v-if="totalPages > 1">


    <ul class="pagination listjs-pagination mb-0">
      <li>
      <button
          :class="{disabled: isInFirstPage}"
            class="page-item pagination-prev"
            type="button"
            @click="onClickPreviousPage"
            :disabled="isInFirstPage"
            aria-label="Go to previous page"
        >
          Previous
        </button>
      </li>
      <li :key="page" v-for="page in pages"
          :class="{ active: isPageActive(page.name), disabled: page.isDisabled}"
      >
        <button
            class="page"
            type="button"
            @click="onClickPage(page.name)"
            :disabled="page.isDisabled"
            :class="{ active: isPageActive(page.name) }"
            :aria-label="`Go to page number ${page.name}`"

        >
          {{ page.name }}
        </button>
      </li>

      <li>
        <button
            :class="{ disabled: isInLastPage}"
            class="page-item pagination-next"
            type="button"
            @click="onClickNextPage"
            :disabled="isInLastPage"
            aria-label="Go to next page"
        >
          Next
        </button>
      </li>
    </ul>

  </div>
  <!-- Sidebar -->
</template>
