<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import Layout from "./../layouts/main";
import appConfig from "../../app.config";

import Lottie from "@/components/widgets/lottie.vue";
import profit from "@/components/profit";
import animationData from "@/components/widgets/msoeawqm.json";
import moment from 'moment';
import MobileListItem from "@/components/deals/mobile-list-item";
import Deal from "@/state/entities/deal";
import Bot from "@/state/entities/bot";
import CoinInfoFetcherService from "@/services/CoinInfoFetcherService";
import Coin from "@/state/entities/coin";
import StandardPagination from "@/components/standard-pagination";

export default {
  components: {
    StandardPagination,
    MobileListItem,
    // ListItem,
    Layout,
    lottie: Lottie,
    Multiselect,
    flatPickr,
    profit
  },
  page: {
    title: "Deals",
    meta: [{ name: "Deals", content: appConfig.description }],
  },
  data() {
    return {
      date: null,
      filterDates: null,
      rangeDateconfig: {
        mode: "range",
        altInput: true,
        altFormat: "F j, Y",
        dateFormat: "Y-m-d",
      },
      status: null,
      marketType: null,
      page: 1,
      perPage: 15,
      totalRecords: 0,
      pages: [],
      dataLoaded: false,
      defaultOptions: { animationData: animationData },
    };
  },
  async beforeMount() {
    (await Bot.api().get('/bot'));
    this.status = this.$route.query.status;
    this.marketType = this.$route.query.marketType;
    this.fetch();
  },
  computed: {
    rows() {
      return Deal.query().with(["bot", "coin"]).orderBy('closed_at', 'desc').all().filter(
          i=>i.status==='completed'
      );
    },
    isMobile() {
      return screen.availWidth < 700
    }
  },
  watch: {
    status() {
      this.fetch();
    },
    marketType() {
      this.fetch();
    },
    filterDates() {
      this.fetch();
    },
    page() {
      this.fetch();
    },
    rows() {
      this.setPages();
    },
  },
  created() {
    this.setPages();
  },
  methods: {
    onPageChange(page) {
      this.page = page;
    },
    async fetchCoins() {
      if(!this.dataLoaded){
        return false;
      }
      for (const row of this.rows) {
        let storageCoin = localStorage.getItem(row.quote_asset.toUpperCase());
        if (!storageCoin) {
          let coinInfoResponse = await CoinInfoFetcherService.coinInfo(row.quote_asset.toUpperCase())
          storageCoin = JSON.stringify(coinInfoResponse.data);
          localStorage.setItem(coinInfoResponse.data.code, storageCoin);
        }
        let coinInfo = JSON.parse(storageCoin);
        const coin = new Coin();
        coin.title = coinInfo.title;
        coin.code = coinInfo.code;
        coin.image_path = coinInfo.image_path;
        await coin.$save();
      }
    },
    parseCreatedFilter(dates) {
      if(dates.length === 2) {
        this.filterDates = dates;
      }
    },
    async fetch() {
      let criteria = {
        status: this.status,
        marketType: this.marketType,
        take: this.perPage,
        page: this.page,
      }
      if (this.filterDates && this.filterDates.length === 2) {
        criteria.created_from = moment(this.filterDates[0]).format('YYYY-MM-DD')
        criteria.created_till = moment(this.filterDates[1]).format('YYYY-MM-DD')
      }
      await Deal.deleteAll();
      const deals = await Deal.api().fetch(criteria);
      this.totalRecords = deals.response.data.meta.item_count;
      await this.fetchCoins();
      this.dataLoaded = true;
    },
    formatPrice(value) {
      let val = (value/1).toFixed(2);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    setPages() {
      this.pages = [];
      let numberOfPages = Math.ceil(this.totalRecords / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row" id="contactList">
      <div class="col-lg-12">
        <div class="card">
          <div
            class="card-body border border-dashed border-end-0 border-start-0"
          >
            <div class="row g-2">
              <div class="col-xl-2 col-md-4">
                <Multiselect
                  class="form-control"
                  v-model="marketType"
                  :close-on-select="true"
                  :searchable="false"
                  :create-option="true"
                  :placeholder=" $t('t-ad-market-type')"
                  :options="[
                    { value: 'spot', label: 'Spot' },
                    { value: 'futures', label: 'Futures' },
                  ]"
                />
              </div>
<!--              <div class="col-xl-2 col-md-4">-->
<!--                <Multiselect-->
<!--                  class="form-control"-->
<!--                  v-model="status"-->
<!--                  :close-on-select="true"-->
<!--                  :searchable="false"-->
<!--                  :create-option="true"-->
<!--                  :options="[-->
<!--                    { value: '', label: 'Select Status' },-->
<!--                    { value: 'active', label: 'Active' },-->
<!--                    { value: 'failed', label: 'Failed' },-->
<!--                    { value: 'completed', label: 'Completed' },-->
<!--                  ]"-->
<!--                />-->
<!--              </div>-->
              <div class="col-xl-3 col-md-6">
                <div class="input-group">
                  <span class="input-group-text" id="basic-addon1">
                    <i class="ri-calendar-2-line"></i>
                  </span>

                  <flat-pickr
                      v-model="date"
                      :config="rangeDateconfig"
                      @on-change="parseCreatedFilter"
                      class="form-control"
                  ></flat-pickr>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
          <div class="card-body">
<!--            <list-item :deal="deal" v-for="(deal, index) of rows" :key="index"></list-item>-->
            <div v-if="isMobile">
              <mobile-list-item :deal="deal" v-for="(deal, index) of rows" :key="index"></mobile-list-item>
            </div>
            <div class="table-responsive table-card" v-if="! isMobile">
              <table class="table align-middle table-nowrap" id="customerTable">
                <thead class="table-light text-muted">
                  <tr>
                    <th class="sort" data-sort="currency_name" scope="col">
                      {{ $t("t-order-history-coin-name") }}
                    </th>
                    <th class="sort" data-sort="bot" scope="col">
                      {{ $t("t-order-history-bot") }}
                    </th>
                    <th class="sort" data-sort="quantity_value" scope="col">
                      {{ $t("t-order-history-quantity") }}
                    </th>
                    <th class="sort" data-sort="order_value" scope="col">
                      {{ $t("t-order-history-purchase-price") }}
                    </th>
                    <th class="sort" data-sort="price" scope="col">
                      {{ $t("t-order-history-profit") }}
                    </th>
                    <th class="sort" data-sort="price" scope="col">
                      {{ $t("t-income-fee") }}
                    </th>
                    <th class="sort" data-sort="open_date" scope="col">
                      {{ $t("t-order-history-open-date") }}
                    </th>
                    <th class="sort" data-sort="close_date" scope="col">
                      {{ $t("t-order-history-close-date") }}
                    </th>
                  </tr>
                </thead>
                <!--end thead-->
                <tbody class="list form-check-all">
                <tr v-for="(deal, index) of rows" :key="index">
                  <td>
                    <div class="d-flex align-items-center fw-medium">
                      <img :src="deal.coin ? deal.coin.image_path : ''" alt="" class="avatar-xxs me-2" />
                      <a href="javascript:void(0)" class="currency_name">{{ deal.coin ? deal.coin.fullTitle : '' }}</a>
                    </div>
                  </td>
                  <td class="bot_id">{{ deal.bot ? deal.bot.title : '-' }}</td>
                  <td class="quantity_value">{{ deal.quote_volume }}</td>
                  <td class="purchase-price">{{ deal.start_price }} {{ deal.base_asset }}</td>
                  <td class="profit">
                    <profit :profit="deal.profit"></profit>
                  </td>
                  <td class="commission">
                    0
                  </td>
                  <td class="order_date">
                    {{ deal.created_date }}
                    <small class="text-muted">{{ deal.created_time }}</small>
                  </td>
                  <td class="order_date">
                    {{ deal.closed_at ? deal.closed_date : '' }}
                    <small class="text-muted">{{ deal.closed_at ? deal.closed_time : '' }}</small>
                  </td>
                </tr>
                </tbody>
                <tfoot  v-show="rows.length === 0 && dataLoaded === false">
                <tr>
                  <td colspan="7" class="text-center">
                    <div class="spinner-border text-danger" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </td>
                </tr>
                </tfoot>
              </table>
              <div
                class="noresult"
                style="display: none"
                :class="{ 'd-block': rows.length === 0 && dataLoaded === true }"
              >
                <div class="text-center">
                  <lottie
                    class="avatar-xl"
                    colors="primary:#121331,secondary:#08a88a"
                    :options="defaultOptions"
                    :height="75"
                    :width="75"
                  />
                  <h5 class="mt-2">Sorry! No Result Found</h5>
                  <p class="text-muted mb-0">
                    No historical deals yet!
                  </p>
                </div>
              </div>
              <!--end table-->
            </div>
            <div class="d-flex justify-content-end p-3">
              <standard-pagination
                  :total-pages="this.pages.length"
                  :total="this.totalRecords"
                  :per-page="this.perPage"
                  :current-page="page"
                  @page-changed="onPageChange"
              />
            </div>
          </div>
        </div>
        <!--end card-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </Layout>
</template>
