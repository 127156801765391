<script>
import Deal from "@/state/entities/deal";
import Coin from "@/state/entities/coin";

export default {
  props: {
    deal: {
      type: Deal,
    },
  },
  computed: {
    coin() {
      let coinName = this.deal.quote_asset;
      const coinInfo = JSON.parse(localStorage.getItem(coinName.toUpperCase()));
      let coin = new Coin();
      coin.code = coinInfo.code;
      coin.title = coinInfo.title;
      coin.image_path = coinInfo.image_path;

      return coin;
    }
  },
  methods: {
    openDealModal(Deal) {
      this.$emit('open-deal-modal-mobile-event', Deal);
    },
    capitalized(string) {
      const capitalizedFirst = string[0].toUpperCase();
      const rest = string.slice(1);

      return capitalizedFirst + rest;
    },
  }
};
</script>

<template>
  <div class="card mt-1 mb-1 ribbon-box ribbon-fill ribbon-sm">
<!--    <div class="ribbon ribbon-info"><i class="ri-flashlight-fill"></i></div>-->
    <div class="card-body" style="padding: 1rem 0rem;">
      <a class="" data-bs-toggle="collapse" :href='"#assetDiscovered" + deal._id'
         role="button" aria-expanded="false" :aria-controls='"assetDiscovered" + deal._id'>
        <div class="row" style="margin: 0px;">
          <div class="col-1">
            <img :src="deal.coin? deal.coin.image_path : ''" class="avatar-xxs" style="margin-top: 5px;" alt=""/>
          </div>
          <div class="col-3">
            <h6 class="fs-11 mb-0" style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">{{deal.coin? deal.coin.title: ''}}</h6>
            <p class="fs-12 text-muted mb-0">{{deal.quote_asset}}</p>
          </div>
          <div class="col-4 text-end">
            <h6 class="fs-12 mb-0">
              {{deal.profit ? parseFloat(deal.profit.baseValue).toFixed(2) : '--'}} $
              <div>{{deal.bot.presets}}</div>
            </h6>
            <p :class="{'fs-12 mb-0 text-danger': this.deal.profit.baseValue < 0, 'fs-12 mb-0 text-success': this.deal.profit.baseValue >= 0}" v-if="deal.profit">
              <i :class="{'ri-arrow-down-s-fill': parseFloat(deal.profit.baseValue).toFixed(3) < 0, 'ri-arrow-up-s-fill': parseFloat(deal.profit.baseValue).toFixed(3) >= 0 }"></i>
              <span v-if="deal.profit.percentage < 0" style="font-weight: bold">{{deal.profit.percentage * -1}} %</span>
              <span v-if="deal.profit.percentage >= 0" style="font-weight: bold">{{deal.profit.percentage}} %</span>
            </p>
          </div>
          <div class="col-4 text-end">
            <h6 class="fs-12 mb-0">{{ parseFloat(deal.base_volume).toFixed(2) }} $</h6>
            <p class="fs-12 mb-0 text-muted">{{ deal.quote_volume }} {{deal.quote_asset}}</p>
          </div>
        </div>
      </a>
    </div>
    <div class="collapse border-top border-top-dashed" :id='"assetDiscovered" + deal._id'>
      <div class="card-body">
        <div class="row">
        <div class="col-6">
          <ul class="list-unstyled vstack gap-2 mb-0">
            <li>
              <div class="d-flex">
                <div class="flex-shrink-0 avatar-xxs text-muted">
                  <i class="ri-robot-fill"></i>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mb-0">{{ $t("t-order-history-bot") }}</h6>
                  <small class="text-muted">
                    {{ deal.bot.title }}
                  </small>
                </div>
              </div>
            </li>
            <li>
              <div class="d-flex">
                <div class="flex-shrink-0 avatar-xxs text-muted">
                  <i class="ri-exchange-dollar-fill"></i>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mb-0">{{ $t("t-ad-purchase-price") }}</h6>
                  <small class="text-muted">
                    {{ deal.start_price }} {{ deal.base_asset }}
                  </small>
                </div>
              </div>
            </li>
            <li>
              <div class="d-flex">
                <div class="flex-shrink-0 avatar-xxs text-muted">
                  <i class="ri-time-line"></i>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mb-0">{{ $t("t-order-history-open-date") }}</h6>
                  <small class="text-muted">
                    {{ deal.created_date }}
                    <small>{{ deal.created_time }}</small>
                  </small>
                </div>
              </div>
            </li>
<!--            <li>-->
<!--              <div class="d-flex">-->
<!--                <div class="flex-shrink-0 avatar-xxs text-muted">-->
<!--                  <i class="ri-price-tag-3-line"></i>-->
<!--                </div>-->
<!--                <div class="flex-grow-1">-->
<!--                  <h6 class="mb-0">{{ $t("t-order-history-average-price") }}</h6>-->
<!--                  <small class="text-muted">{{ deal.start_price }}</small>-->
<!--                </div>-->
<!--              </div>-->
<!--            </li>-->
            <li>
              <div class="d-flex">
                <div class="flex-shrink-0 avatar-xxs text-muted">
                  <i class="ri-percent-line"></i>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mb-0">{{ $t("t-order-take-profit") }}</h6>
                  <small class="text-muted">{{ deal.take_profit }} %</small>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="col-6">
          <ul class="list-unstyled vstack gap-2 mb-0">
            <li>
              <div class="d-flex">
                <div class="flex-shrink-0 avatar-xxs text-muted">
                  <i class="ri-exchange-line"></i>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mb-0">{{ $t("t-order-pair") }}</h6>
                  <small class="text-muted">
                    {{ deal.pairFormatted }}
                  </small>
                </div>
              </div>
            </li>
            <li>
              <div class="d-flex">
                <div class="flex-shrink-0 avatar-xxs text-muted">
                  <i class="ri-price-tag-3-line"></i>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mb-0">{{ $t("t-ad-purchase-type") }}</h6>
                  <small class="text-muted">
                    {{ this.capitalized(deal.market_type) }}
                    <span class="badge badge-soft-danger fs-11" v-if="deal.market_type === 'futures' && deal.take_profit < 0">
                      SHORT
                    </span>
                    <span class="badge badge-soft-success fs-11" v-if="deal.market_type === 'futures' && deal.take_profit > 0">
                      LONG
                    </span>
                  </small>
                </div>
              </div>
            </li>
            <li>
              <div class="d-flex">
                <div class="flex-shrink-0 avatar-xxs text-muted">
                  <i class="ri-time-fill"></i>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mb-0">{{ $t("t-order-history-close-date") }}</h6>
                  <small class="text-muted" v-if="deal.closed_date">
                    {{ deal.closed_date }}
                    <small>{{ deal.closed_time }}</small>
                  </small>
                  <small class="text-muted" v-if="deal.closed_date === null">
                    -
                  </small>
                </div>
              </div>
            </li>
<!--            <li>-->
<!--              <div class="d-flex">-->
<!--                <div class="flex-shrink-0 avatar-xxs text-muted">-->
<!--                  <i class="ri-align-vertically"></i>-->
<!--                </div>-->
<!--                <div class="flex-grow-1">-->
<!--                  <h6 class="mb-0">{{ $t("t-order-history-averaging-count") }}</h6>-->
<!--                  <small class="text-muted">-</small>-->
<!--                </div>-->
<!--              </div>-->
<!--            </li>-->
            <li>
              <div class="d-flex">
                <div class="flex-shrink-0 avatar-xxs text-muted">
                  <i class="ri-focus-line"></i>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mb-0">{{ $t("t-order-status") }}</h6>
                  <small class="text-muted">
                     <span class="badge fs-11 badge-soft-success" v-if="deal.status === 'completed'">
                       {{ $t("t-order-status-completed") }}
                    </span>
                    <span class="badge fs-11 badge-soft-danger" v-if="deal.status === 'failed'">
                      {{ $t("t-order-status-failed") }}
                    </span>
                    <span class="badge fs-11 badge-soft-info" v-if="deal.status === 'active'">
                      {{ $t("t-order-status-active") }}
                    </span>
                  </small>
                </div>
              </div>
            </li>
          </ul>
        </div>
        </div>
      </div>
      <div class="card-footer text-center" v-if="deal.status === 'active'">
        <a href="javascript:void(0);" class="btn btn-success" id="sa-close" @click="openDealModal(deal)">
          <span v-if="deal.market_type === 'spot'">{{ $t("t-sell")}}</span>
          <span v-if="deal.market_type === 'futures'">{{ $t("t-close-button")}}</span>
        </a>
      </div>
    </div>
  </div>
</template>
